import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import MainLayout from '../ui/layouts/MainLayout'
import SubmissionSuccessView from '../ui/views/SubmissionSuccessView'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Page = props => (
  <>
    <HelmetComponent
      description={metaTags.contact}
      page="register-success"
      script={`gtag('event', 'conversion', {'send_to': 'AW-494424091/Q2yyCM-Cuv4CEJug4esB'});`}
    />
    <AppWrapper {...props}>
      <MainLayout>
        <SubmissionSuccessView />
      </MainLayout>
    </AppWrapper>
  </>
)

export default Page
